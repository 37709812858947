import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PaginationParams } from '@/composables/types/usePagination'

import {
  User,
  UserUpdate,
  UsersByRoleRequest,
  UseGetUsers,
  UseGetUser,
  UseCreateUser,
  UseUpdateUser,
  UseDeleteUser,
  UseInvalidateToken,
  UseGetUsersBasic,
} from '@/api/types/user'
import { BasicEntity } from './types/misc'

const VERSION = 'v1'
const RESOURCE = 'users'

export const useGetUsers = (): UseGetUsers => {
  const axios = useAxiosPaginated<User>({
    method: 'GET',
    url: `${VERSION}/${RESOURCE}`,
  })

  const getUsers = (paginationParams?: PaginationParams, params?: UsersByRoleRequest) =>
    axios.exec({ params: { ...paginationParams, ...params } })

  return {
    ...axios,
    getUsers,
  }
}

function useGetUsersBasic(): UseGetUsersBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })

  return axios
}

export const useGetUser = (): UseGetUser => {
  const axios = useAxios<User>({
    method: 'GET',
  })

  const getUser = (userId: string) => axios.exec({ url: `${VERSION}/${RESOURCE}/${userId}` })

  return {
    ...axios,
    getUser,
  }
}

export const useCreateUser = (): UseCreateUser => {
  const axios = useAxios<User>({
    method: 'POST',
    url: `${VERSION}/${RESOURCE}`,
  })

  const createUser = (user: UserUpdate) => axios.exec({ data: user })

  return {
    ...axios,
    createUser,
  }
}
export const useUpdateUser = (): UseUpdateUser => {
  const axios = useAxios<User>({
    method: 'PUT',
  })

  const updateUser = (userId: number, user: UserUpdate) =>
    axios.exec({ url: `${VERSION}/${RESOURCE}/${userId}`, data: user })

  return {
    ...axios,
    updateUser,
  }
}
export const useDeleteUser = (): UseDeleteUser => {
  const axios = useAxios<void>({
    method: 'DELETE',
  })

  const deleteUser = (userId: number) => axios.exec({ url: `${VERSION}/${RESOURCE}/${userId}` })

  return {
    ...axios,
    deleteUser,
  }
}

export const useInvalidateToken = (): UseInvalidateToken => {
  const axios = useAxios<void>({ method: 'POST' })

  const invalidateToken = (userId: number) => axios.exec({ url: `${VERSION}/${RESOURCE}/${userId}/terminate` })

  return {
    ...axios,
    invalidateToken,
  }
}

export { useGetUsersBasic }
