
import { computed, defineComponent, onBeforeMount, PropType, reactive, ref, watch } from '@vue/composition-api'

import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'

import { isEndingDateBeforeStartingDate, isPercentageRange, isRequired } from '@/utils/validation'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { useGetProfilesBasic } from '@/api/profile'

import { PositionOutput } from '@/api/types/position'
import { POSITION_STATUS, SOURCING_TYPE } from '@/views/planning/views/workforcePlanning/types'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { useGetUsersBasic } from '@/api/user'
import { Rights } from '@/api/types/right'
import { NewTransactionInput } from '@/api/types/positionTransaction'
import { useGetPositionsBasic } from '@/api/position'
import { PROFILE_TYPE } from '@/api/types/profile'

export default defineComponent({
  name: 'PositionForm',
  components: {
    CommonNumberInput,
    CommonAutocomplete,
    CommonDateInput,
  },
  props: {
    value: {
      type: Object as PropType<PositionOutput>,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    selectedInputPositions: {
      type: Array as PropType<NewTransactionInput[]>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const formElement = ref<HTMLFormElement | null>(null)

    const isFormValid = ref(true)

    const form = computed<PositionOutput>({
      get: () => props.value,
      set: (value) => value,
    })

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()
    getOrgUnitsBasic()

    const { exec: getProfUnitsBasic, data: profUnits, isLoading: isLoadingProfUnitsBasic } = useGetProfUnitsBasic()
    getProfUnitsBasic()

    const { exec: getProfilesBasic, data: profiles, isLoading: isLoadingProfilesBasic } = useGetProfilesBasic()
    getProfilesBasic({ params: { type: PROFILE_TYPE.ASSIGNMENT } })

    const { exec: getUsersBasic, data: users, isLoading: isLoadingUsersBasic } = useGetUsersBasic()
    getUsersBasic()

    const { exec: getPositionsBasic, data: positions, isLoading: isLoadingPositionsBasic } = useGetPositionsBasic()
    getPositionsBasic()

    const filteredPositionsBasic = computed(() => {
      if (!positions.value) return

      return positions.value?.filter((positionBasic) =>
        props.selectedInputPositions.some((inputPosition) => positionBasic.id === inputPosition.position)
      )
    })

    onBeforeMount(() => emit('setFormfields', FORM_FIELDS))

    const FORM_FIELDS: FormField[] = [
      {
        value: 'start',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.start') as string)],
      },
      {
        value: 'end',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.budgetSourceUnit') as string),
          (value: string) => (props.value?.start ? isEndingDateBeforeStartingDate(props.value.start, value) : true),
        ],
      },
      {
        value: 'profile',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => profiles.value ?? []),
        isLoading: computed(() => isLoadingProfilesBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.profile') as string)],
        dropdownTextProp: 'name',
      },
      {
        value: 'budgetSourceOrgUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.budgetSourceUnit') as string),
        ],
        dropdownTextProp: 'name',
      },
      {
        value: 'organizationalUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.budgetSourceUnit') as string),
        ],
        dropdownTextProp: 'name',
      },
      {
        value: 'professionalUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnitsBasic.value),
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.professionalUnit') as string),
        ],
        dropdownTextProp: 'name',
      },
      {
        value: 'scope',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.scope') as string),
          (value: string) => isPercentageRange(value),
          (value: string) => isNotZero(value),
        ],
      },
      {
        value: 'sourcingType',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => SOURCING_TYPE ?? []),
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.sourcingType') as string),
        ],
        dropdownTextProp: 'name',
      },
      hasSufficientRights(Rights.FINANCIAL_READ)
        ? {
            value: 'hourlyRate',
            fieldType: FORM_FIELDS_ENUM.TEXT,
            dataTyp: DATA_TYPE.NUMBER,
            isRequired: true,
            rules: [
              (value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.hourlyRate') as string),
            ],
          }
        : ({} as FormField),
      {
        value: 'dailyWorkingTime',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.dailyWorkingTime') as string),
        ],
      },
      {
        value: 'plannedPerson',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'positionStatus',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => POSITION_STATUS ?? []),
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.positionStatus') as string),
        ],
        dropdownTextProp: 'name',
      },
      {
        value: 'description',
        fieldType: FORM_FIELDS_ENUM.TEXTAREA,
        isRequired: false,
        rules: [],
      },
      {
        value: 'commitMessage',
        fieldType: FORM_FIELDS_ENUM.TEXTAREA,
        isRequired: props.isEditMode ? true : false,
        rules: props.isEditMode
          ? [(value: string) => isRequired(value, root.$t('planning.workforcePlanning.form.commitMessage') as string)]
          : [],
      },
      {
        value: 'approvals',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => users.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
        fieldOptions: [{ multiple: true }],
      },
      {
        value: 'limited',
        fieldType: FORM_FIELDS_ENUM.CHECKBOX,
        dataTyp: DATA_TYPE.BOOLEAN,
        isRequired: false,
        rules: [],
      },
      props.selectedInputPositions
        ? {
            value: 'ancestor',
            fieldType: FORM_FIELDS_ENUM.DROPDOWN,
            items: computed(() => filteredPositionsBasic.value ?? []),
            isLoading: computed(() => isLoadingPositionsBasic.value),
            isRequired: false,
            rules: [],
            dropdownTextProp: 'name',
          }
        : ({} as FormField),
    ]

    watch(
      () => isFormValid.value,
      () => {
        emit('validationChange', isFormValid.value)
      }
    )

    const highHourlyRate = ref<boolean>(false)

    watch(
      () => props.value?.hourlyRate,
      () => {
        return (highHourlyRate.value = Number(props.value?.hourlyRate) >= 300 ? true : false)
      }
    )

    function isNotZero(scope: string): string | boolean {
      return scope === '0' ? (root.$t('planning.workforcePlanning.form.error.outputScope') as string) : true
    }

    return reactive({
      formElement,
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isFormValid,
        form,

        highHourlyRate,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
