var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._g(_vm._b({
    attrs: {
      "value": _vm.value,
      "hint": "DD.MM.YYYY",
      "rules": [].concat(_vm.rules, [_vm.formRules.isValidDotNotationDate(_vm.value)])
    }
  }, 'v-text-field', _vm.$attrs, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }