var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-form', {
    ref: "formElement",
    model: {
      value: _vm.state.isFormValid,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFormValid", $$v);
      },
      expression: "state.isFormValid"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.constants.FORM_FIELDS, function (entry) {
    var _entry$isLoading$valu, _entry$isLoading;

    return _c('div', {
      key: entry.value,
      staticClass: "output-form-field--width flex-grow-1 px-4"
    }, [entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXT ? [entry.dataTyp === _vm.constants.DATA_TYPE.TEXT ? _c('v-text-field', {
      class: "".concat(entry.isRequired && 'required', " "),
      attrs: {
        "value": _vm.value[entry.value],
        "label": _vm.$t("planning.workforcePlanning.form.".concat(entry.value)),
        "rules": entry.rules,
        "readonly": !_vm.isEditable
      },
      model: {
        value: _vm.state.form[entry.value],
        callback: function callback($$v) {
          _vm.$set(_vm.state.form, entry.value, $$v);
        },
        expression: "state.form[entry.value]"
      }
    }) : entry.dataTyp === _vm.constants.DATA_TYPE.NUMBER ? _c('CommonNumberInput', {
      class: "".concat(entry.isRequired && 'required'),
      attrs: {
        "has-high-hourly-rate": entry.value === 'hourlyRate' && _vm.state.highHourlyRate,
        "label": _vm.$t("planning.workforcePlanning.form.".concat(entry.value)),
        "rules": [].concat(entry.rules, [!_vm.state.highHourlyRate]),
        "is-required": entry.isRequired,
        "readonly": !_vm.isEditable
      },
      on: {
        "confirm": function confirm($event) {
          _vm.state.highHourlyRate = false;
        }
      },
      model: {
        value: _vm.state.form[entry.value],
        callback: function callback($$v) {
          _vm.$set(_vm.state.form, entry.value, $$v);
        },
        expression: "state.form[entry.value]"
      }
    }) : entry.dataTyp === _vm.constants.DATA_TYPE.DATE ? _c('CommonDateInput', {
      class: "".concat(entry.isRequired && 'required'),
      attrs: {
        "label": _vm.$t("planning.workforcePlanning.form.".concat(entry.value)),
        "rules": entry.rules,
        "is-required": entry.isRequired,
        "readonly": !_vm.isEditable
      },
      model: {
        value: _vm.state.form[entry.value],
        callback: function callback($$v) {
          _vm.$set(_vm.state.form, entry.value, $$v);
        },
        expression: "state.form[entry.value]"
      }
    }) : _vm._e()] : entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.DROPDOWN ? _c('CommonAutocomplete', _vm._b({
      class: "".concat(entry.isRequired && 'required', " "),
      attrs: {
        "items": entry.items.value,
        "item-text": entry.dropdownTextProp,
        "item-value": "id",
        "loading": (_entry$isLoading$valu = (_entry$isLoading = entry.isLoading) === null || _entry$isLoading === void 0 ? void 0 : _entry$isLoading.value) !== null && _entry$isLoading$valu !== void 0 ? _entry$isLoading$valu : false,
        "rules": [].concat(entry.rules),
        "label": _vm.$t("planning.workforcePlanning.form.".concat(entry.value)),
        "readonly": !_vm.isEditable
      },
      model: {
        value: _vm.state.form[entry.value],
        callback: function callback($$v) {
          _vm.$set(_vm.state.form, entry.value, $$v);
        },
        expression: "state.form[entry.value]"
      }
    }, 'CommonAutocomplete', [].concat(entry.fieldOptions), false)) : _vm._e(), entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXTAREA ? _c('v-textarea', {
      class: "".concat(entry.isRequired && 'required'),
      attrs: {
        "label": _vm.$t("planning.workforcePlanning.form.".concat(entry.value)),
        "rules": [].concat(entry.rules),
        "auto-grow": "",
        "rows": "1",
        "readonly": !_vm.isEditable
      },
      model: {
        value: _vm.state.form[entry.value],
        callback: function callback($$v) {
          _vm.$set(_vm.state.form, entry.value, $$v);
        },
        expression: "state.form[entry.value]"
      }
    }) : _vm._e(), entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.CHECKBOX ? _c('v-checkbox', {
      attrs: {
        "label": _vm.$t("planning.workforcePlanning.form.".concat(entry.value)),
        "readonly": !_vm.isEditable
      },
      model: {
        value: _vm.state.form[entry.value],
        callback: function callback($$v) {
          _vm.$set(_vm.state.form, entry.value, $$v);
        },
        expression: "state.form[entry.value]"
      }
    }) : _vm._e()], 2);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }